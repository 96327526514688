body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.loading-root {
    background-color: rgb(0, 1, 41);
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
}
.center-div {
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    -webkit-box-shadow:0 0 50px blue; 
    -moz-box-shadow: 0 0 50px blue; 
    box-shadow:0 0 50px blue;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.center-div div {
    font-size: 60px;
    background-color: rgb(0, 1, 41);
    display: inline-block;
    color: rgb(174, 215, 241);
    border: 2px solid rgb(107, 180, 243);
    width: 300px;
    height: 300px;
    border-radius: 50%;
    -webkit-animation-name: inner-center-div;
    -webkit-animation-duration: 4s; 
    -webkit-animation-iteration-count: infinite; 
    animation-name: inner-center-div;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}

@-webkit-keyframes inner-center-div {
    0% {transform: scale(1.0);}
    50% {transform: scale(1.1);}
    100% {transform: scale(1.0);}
}


@keyframes inner-center-div {
    0% {transform: scale(1.0);}
    50% {transform: scale(1.1);}
    100% {transform: scale(1.0);}
}




.center-div div p {
    margin-top: 25%;
    font-family: 'Lobster', cursive;
}
